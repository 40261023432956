import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {
  mdiEye,
  mdiEyeOff,
  mdiCheck,
  mdiCheckDecagram,
  mdiCheckDecagramOutline,
  mdiCancel,
  mdiClose,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCloseCircle,
  mdiMenuDown,
  mdiMenu,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiSend,
  mdiCheckboxMarkedCircleOutline,
  mdiMagnify,
  mdiViewDashboardOutline,
  mdiAccountEditOutline,
  mdiLogout,
  mdiAccount,
  mdiInformationOutline,
  mdiTextBoxSearch,
  mdiTextBoxSearchOutline,
  mdiExitToApp,
  mdiClipboardOutline,
  mdiAccountBox,
  mdiHome,
  mdiAccountCircle,
  mdiChevronUp,
  mdiChevronDown,
  mdiPlay,
  mdiRestore,
  mdiStop,
  mdiContentSave,
  mdiCloseCircleOutline,
  mdiPencil,
  mdiDelete,
  mdiCheckboxMarkedCircle,
  mdiPlus,
  mdiBagPersonal,
} from "@mdi/js";

//Usage '$vuetify.icons.xbox' or $xbox
const MY_ICONS = {
  // jm added
  plus: mdiPlus,
  cancel1: mdiCloseCircleOutline,
  save: mdiContentSave,
  stop: mdiStop,
  loop: mdiRestore,
  play: mdiPlay,
  chevup: mdiChevronUp,
  chevdown: mdiChevronDown,
  person: mdiAccountCircle,
  person2: mdiBagPersonal,
  home: mdiHome,
  checkedcircle: mdiCheckboxMarkedCircle,
  /*Default List*/
  eye: mdiEye,
  eyeoff: mdiEyeOff,
  complete: mdiCheck,
  cancel: mdiCancel,
  close: mdiClose,
  delete: mdiCloseCircle, // delete (e.g. v-chip close)
  del: mdiDelete,
  clear: mdiClose,
  success: mdiCheck,
  successOutline: mdiCheckDecagramOutline,
  successBold: mdiCheckDecagram,
  info: mdiInformationOutline,
  warning: "...",
  error: "...",
  prev: "...",
  next: "...",
  checkboxOn: mdiCheckboxMarked,
  checkboxOff: mdiCheckboxBlankOutline,
  checkboxIndeterminate: "...",
  delimiter: "...", // for carousel
  sort: mdiArrowUp,
  expand: mdiMenuDown,
  menu: mdiMenu,
  subgroup: "...",
  dropdown: mdiMenuDown,
  radioOn: "...",
  radioOff: "...",
  edit: mdiPencil,
  ratingEmpty: "...",
  ratingFull: "...",
  ratingHalf: "...",
  loading: "...",
  first: "...",
  last: "...",
  unfold: "...",
  file: "...",
  /*Additional Icons*/
  arrowLeft: mdiArrowLeft,
  arrowRight: mdiArrowRight,
  send: mdiSend,
  checkboxMarkedOutline: mdiCheckboxMarkedCircleOutline,
  magnify: mdiMagnify,
  search: mdiMagnify,
  appViewDash: mdiViewDashboardOutline,
  accountEdit: mdiAccountEditOutline,
  logout: mdiLogout,
  account: mdiAccount,
  textSearch: mdiTextBoxSearch,
  textSearchOutline: mdiTextBoxSearchOutline,
  exitToApp: mdiExitToApp,
  clipboard: mdiClipboardOutline,
};

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: MY_ICONS,
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 767,
      md: 1024,
      lg: 1904,
    },
    scrollBarWidth: 24,
  },
  theme: {
    themes: {
      defaultTheme: "light",
      light: {
        reportback: "#ffffff",
        background: "#e0e0e0",
        primary: "#283a44",
        secondary: "#C8D5B9",
        accent: "#78806f",
        accent2: "#7D6F80",
        error: "#f44336",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#4caf50",
        cmpbk: "#e0e0e0",
        cmpbkinside: "#ECEFF1",
        expbkg: "#708e92",
        bluebar: "#002e42",
        bluebar_light: "#133848",
      },
      // join: {
      //   background: "#778899",
      //   primary: "#212121",
      //   secondary: "#C8D5B9",
      //   accent: "#6C5D2B",
      //   error: "#f44336",
      //   warning: "#ff9800",
      //   info: "#03a9f4",
      //   success: "#4caf50",
      //   cmpbk: "#D3DEDE",
      // },
    },
    options: {
      customProperties: true,
    },
  },
});
